import {
  Button,
  CheckboxField,
  Divider,
  Flex,
  Heading,
  Link,
} from "@aws-amplify/ui-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdChevronLeft, MdDateRange, MdEdit, MdPerson } from "react-icons/md";
import { useParams } from "react-router-dom";
import DiscoveryHeader from "../Discovery/components/DiscoveryHeader";
import { TextMuted } from "../Discovery/components/DiscoveryTypography";
import Container from "../libs/shared/components/Container";
import { useUserProfile } from "../libs/shared/components/UserProfileProvider";
import useForm from "../libs/shared/hooks/useForm";
import { useIsMatchingMobile } from "../libs/shared/hooks/useIsMatchingMobile";
import { useProperty } from "../libs/shared/hooks/useProperty";
import { useSearchParamsUtils } from "../libs/shared/hooks/useSearchParamsUtils";
import { InquiryParam } from "./InquiryParam";
import PropertyInquiryBottomsheet from "./PropertyInquiryBottomsheet";
import PropertyInquiryCreateButton from "./PropertyInquiryCreateButton";
import PropertyInquiryForm from "./PropertyInquiryForm";
import PropertyInquiryGuestForm, {
  GuestDataForm,
} from "./PropertyInquiryGuestForm";
import LoaderBox from "../libs/shared/components/LoaderBox";

export default function PropertyInquiryPage() {
  const isMatchingMobile = useIsMatchingMobile();
  const { t } = useTranslation();
  const { userProfile, loading } = useUserProfile();
  const params = useParams<{ propertyId: string }>();
  const { searchParams } = useSearchParamsUtils();
  const [creating, setCreating] = useState<boolean>(false);
  const { data, loading: loadingProperty } = useProperty(
    Number(params.propertyId)
  );
  const { formValues, setFieldValue, setFormValues } = useForm<
    {
      marketingOptOut: boolean;
      hostawayId?: number;
      checkinDate?: string;
      checkoutDate?: string;
      numberOfGuests?: number;
    } & GuestDataForm
  >({
    marketingOptOut: false,
    hostawayId: Number(params.propertyId),
    checkinDate: searchParams.get("arrival")!,
    checkoutDate: searchParams.get("departure")!,
    numberOfGuests: Number(searchParams.get("numberOfGuests")!),
  });
  const [openInquiry, setOpenInquiry] = useState<boolean>(false);

  useEffect(() => {
    setFormValues({
      ...formValues,
      guestFirstName: userProfile?.first_name,
      guestLastName: userProfile?.last_name,
      guestEmail: userProfile?.email,
      phone: userProfile?.phone,
    });
  }, [userProfile]);

  const isFastTrackInquiry = useMemo(() => {
    return (
      userProfile &&
      userProfile.first_name &&
      userProfile.last_name &&
      userProfile.phone &&
      userProfile.email
    );
  }, [userProfile]);

  const handleInquiryChange = useCallback(
    (inquiryParams: InquiryParam) => {
      setOpenInquiry(false);

      setFormValues({
        ...formValues,
        checkinDate: inquiryParams.arrival,
        checkoutDate: inquiryParams.departure,
        numberOfGuests: inquiryParams.numberOfGuests,
      });
    },
    [setFieldValue, searchParams]
  );

  return (
    <>
      <DiscoveryHeader></DiscoveryHeader>

      {(loadingProperty || loading) && <LoaderBox></LoaderBox>}
      {!loading && !loadingProperty && (
        <>
          {data && (
            <PropertyInquiryBottomsheet
              defaultInquiry={{
                arrival: formValues.checkinDate,
                departure: formValues.checkoutDate,
                numberOfGuests: formValues.numberOfGuests,
              }}
              listing={data}
              onDismiss={() => setOpenInquiry(false)}
              open={openInquiry}
              onSelected={(inquiry) => {
                setOpenInquiry(false);
                handleInquiryChange(inquiry);
              }}
            />
          )}

          <Container>
            <Flex
              direction={isFastTrackInquiry ? "column" : "row"}
              justifyContent={"space-between"}
              gap={36}
              paddingBottom={32}
            >
              <Flex direction={"column"} grow={1}>
                <Flex alignItems={"center"}>
                  <Link href={`/listings/${data?.id}`}>
                    <MdChevronLeft size={24}></MdChevronLeft>
                  </Link>
                  <Heading level={1}>{t("Property.Book")}</Heading>
                </Flex>

                {data && isMatchingMobile && (
                  <Flex direction={"column"}>
                    <Flex alignItems={"center"}>
                      <TextMuted>{data.name}</TextMuted>
                    </Flex>
                    <Flex justifyContent={"space-between"}>
                      <Flex alignItems={"center"}>
                        <MdDateRange></MdDateRange>
                        <TextMuted>
                          {formValues.checkinDate} - {formValues.checkoutDate}
                        </TextMuted>
                      </Flex>

                      <Flex alignItems={"center"} gap={8}>
                        <MdPerson></MdPerson>
                        <TextMuted>{formValues.numberOfGuests}</TextMuted>
                      </Flex>
                      <Flex alignItems={"center"} gap={8}>
                        <Button
                          variation="link"
                          onClick={() => setOpenInquiry(true)}
                        >
                          <MdEdit></MdEdit>
                        </Button>
                      </Flex>
                    </Flex>
                    <Divider></Divider>
                  </Flex>
                )}

                {!isFastTrackInquiry && (
                  <>
                    <PropertyInquiryGuestForm
                      isReadonly={creating}
                      onUpdate={(guestData) => {
                        setFormValues({ ...formValues, ...guestData });
                      }}
                    />

                    <PropertyInquiryCreateButton
                      formValues={formValues}
                      onLoadingStateChange={setCreating}
                    />

                    <Flex marginTop={16} marginBottom={16}>
                      <Divider />
                    </Flex>

                    <TextMuted>
                      {t("FinalizeBooking.MarketingOptoutDesc")}
                    </TextMuted>

                    <CheckboxField
                      name="privacy"
                      onChange={() => {
                        setFieldValue(
                          "marketingOptOut",
                          !formValues.marketingOptOut
                        );
                      }}
                      value={"privacy"}
                      label={t("FinalizeBooking.MarketingOptout")}
                    ></CheckboxField>
                  </>
                )}
              </Flex>

              {(isFastTrackInquiry || !isMatchingMobile) && (
                <Flex grow={1} justifyContent={"center"}>
                  <Flex maxWidth={450} justifyContent={"center"}>
                    <Flex direction="column">
                      {data && (
                        <PropertyInquiryForm
                          imageUrl={data.listingImages[0].url}
                          onChange={handleInquiryChange}
                          listing={data}
                        ></PropertyInquiryForm>
                      )}
                      {isFastTrackInquiry && (
                        <PropertyInquiryCreateButton
                          formValues={formValues}
                          onLoadingStateChange={setCreating}
                        />
                      )}
                    </Flex>
                  </Flex>
                </Flex>
              )}
            </Flex>
          </Container>
        </>
      )}
    </>
  );
}
