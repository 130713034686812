import { TextField } from "@aws-amplify/ui-react";
import { useTranslation } from "react-i18next";
import PhoneNumberField from "../libs/shared/components/PhoneNumberField";
import useForm from "../libs/shared/hooks/useForm";
import { useEffect } from "react";

export interface GuestDataForm {
  guestFirstName?: string;
  guestLastName?: string;
  guestEmail?: string;
  phone?: string;
}

export default function PropertyInquiryGuestForm({
  onUpdate,
  isReadonly,
}: {
  isReadonly: boolean;
  onUpdate: (values: any) => void;
}) {
  const { t } = useTranslation();
  const { formValues, setFieldValue } = useForm<GuestDataForm>({});

  useEffect(() => {
    onUpdate(formValues);
  }, [formValues]);

  return (
    <>
      <TextField
        disabled={isReadonly}
        label={t("Account.FirstName")}
        name="firstName"
        type="text"
        onChange={(event) => {
          setFieldValue("guestFirstName", event.target.value);
        }}
      ></TextField>
      <TextField
        disabled={isReadonly}
        name="lastName"
        label={t("Account.LastName")}
        type="text"
        onChange={(event) => {
          setFieldValue("guestLastName", event.target.value);
        }}
      ></TextField>
      <TextField
        disabled={isReadonly}
        label={t("Account.Email")}
        type="email"
        name="email"
        onChange={(event) => {
          setFieldValue("guestEmail", event.target.value);
        }}
      ></TextField>

      <PhoneNumberField
        disabled={isReadonly}
        value={formValues.phone!}
        onChange={(value) => {
          setFieldValue("phone", value);
        }}
      ></PhoneNumberField>
    </>
  );
}
