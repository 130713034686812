import { Button, Flex, useBreakpointValue } from "@aws-amplify/ui-react";
import { PropsWithChildren, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import RentujemyComLogo from "../../Guest/components/RentujemyComLogo";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import DiscoveryContact from "./DiscoveryContact";
import DiscoveryMenuMobile from "./DiscoveryMenuMobile";
import LanguageSelectField from "./LanguageSelectField";
import NavButton from "./NavButton";
import { borderBottomStyle } from "../commonStyles";

export default function DiscoveryHeader({ children }: PropsWithChildren) {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const { user } = useCurrentUser();

  // TODO @adrianbarwicki
  // const location = useLocation();
  // const [currentLocation, setCurrentLocation] = useState("");

  const isMatchingMobile = useBreakpointValue({ base: true, medium: false });

  // TODO @adrianbarwicki
  // useEffect(() => {
  //   setCurrentLocation(location.pathname);
  // }, [location.pathname]);

  return (
    <>
      {showModal && (
        <DiscoveryContact
          handleClose={() => setShowModal(false)}
        ></DiscoveryContact>
      )}

      <Flex
        style={borderBottomStyle}
        grow={1}
        justifyContent={"space-between"}
        alignItems="center"
        padding={isMatchingMobile ? 16 : 24}
      >
        <Flex direction={"column"}>
          <Link to={"/"}>
            <RentujemyComLogo></RentujemyComLogo>
          </Link>
        </Flex>

        <>
          <Flex>{children}</Flex>
          <Flex direction={"row"} gap={12}>
            {!isMatchingMobile && (
              <>
                {user && (
                  <NavButton
                    pathname="/trips"
                    label={t("Tabs.Trips")}
                  ></NavButton>
                )}
              </>
            )}

            {!user && !isMatchingMobile && (
              <Button
                variation="link"
                onClick={() => {
                  navigate("/login");
                }}
              >
                {t("Tabs.Login")}
              </Button>
            )}

            <LanguageSelectField />

            <DiscoveryMenuMobile />
          </Flex>
        </>
      </Flex>
    </>
  );
}
