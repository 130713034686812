import { Alert, Button, Flex } from "@aws-amplify/ui-react";
import { PropsWithChildren, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useGuestReservation } from "../hooks/useGuestReservation";
import ReservationContext from "./ReservationProvider";

export const GuestPortalReservationProvider = ({
  hostwayReservationId,
  children,
}: PropsWithChildren<{ hostwayReservationId: number }>) => {
  const { data, error, refetch } = useGuestReservation(hostwayReservationId);
  const { t } = useTranslation();

  const errorAlertContent = useMemo(() => {
    if (!error) {
      return;
    }

    if (error.message === "Failed to fetch") {
      return t("GuestPortal.CouldNotRetrieveReservation");
    }

    if (error.message.includes("No reservation found")) {
      return t("GuestPortal.NoReservationFound");
    }

    if (error.message.includes("invalid input syntax for type integer")) {
      return t("GuestPortal.WrongLink");
    }

    return (
      <Flex direction={"column"}>
        <Flex>{t("GuestPortal.CouldNotRetrieveReservation")}</Flex>
        <Flex>{error.message}</Flex>
      </Flex>
    );
  }, [error]);

  return (
    <ReservationContext.Provider value={{ reservation: data }}>
      {error && (
        <Flex direction={"column"}>
          <Alert variation="error">{errorAlertContent}</Alert>

          <Button onClick={() => refetch()}>{t("GuestPortal.TryAgain")}</Button>
        </Flex>
      )}

      {!error && <>{children}</>}
    </ReservationContext.Provider>
  );
};
