import { PropsWithChildren, createContext, useContext } from "react";
import { GuestPublicContext } from "../types/GuestPublicContext";
import { GuestPortalReservationProvider } from "./GuestPortalReservationProvider";
import { useSessionContext } from "./SessionProvider";

export interface ReservationContextInterface {
  reservation: GuestPublicContext | undefined;
}

const ReservationContext = createContext<
  ReservationContextInterface | undefined
>(undefined);

export default ReservationContext;

export const useReservationContext = () => {
  const context = useContext(ReservationContext);

  if (!context) {
    throw new Error(
      "useReservationContext must be used within a ReservationProvider"
    );
  }

  return context;
};

export const ReservationProvider = ({ children }: PropsWithChildren) => {
  const { session } = useSessionContext();

  return (
    <>
      {session && (
        <GuestPortalReservationProvider
          hostwayReservationId={session.reservationId}
        >
          {children}
        </GuestPortalReservationProvider>
      )}
    </>
  );
};
