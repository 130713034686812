import { Button } from "@aws-amplify/ui-react";
import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  InquiryCreateParams,
  useCreateInquiry,
} from "../hooks/useGuestReservations";
import i18n from "../i18n/i18n";
import Loader from "../libs/shared/components/Loader";
import useNavigateToExternal from "../libs/shared/hooks/useNavigateToExternal";
import PropertyInquiryErrors from "./PropertyInquiryErrors";
import TextMuted from "../libs/shared/components/TextMuted";
import { privacyLink, termsLink } from "../libs/shared/constants";

export default function PropertyInquiryCreateButton({
  formValues,
  onLoadingStateChange,
}: {
  formValues: Partial<Omit<InquiryCreateParams, "guestLocale">>;
  onLoadingStateChange: (loading: boolean) => void;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const navigateToExternal = useNavigateToExternal();
  const {
    data: inquiry,
    create,
    loading: creating,
    error,
  } = useCreateInquiry();

  useEffect(() => {
    onLoadingStateChange(creating);
  }, [creating]);

  useEffect(() => {
    if (inquiry) {
      if (inquiry.paymentUrl) {
        // Will open stripe checkout session in new tab - this cuts one step in the process increasing conversion
        navigateToExternal(inquiry.paymentUrl, true);
      }

      // App navigates to the finalize page
      navigate(`/gp/${inquiry.id}/finalize`);
    }
  }, [inquiry, navigateToExternal]);

  return (
    <>
      <Button
        size="large"
        isLoading={creating}
        variation="primary"
        onClick={async () => {
          await create({
            guestEmail: formValues.guestEmail!,
            hostawayId: formValues.hostawayId!,
            checkinDate: formValues.checkinDate!,
            checkoutDate: formValues.checkoutDate!,
            numberOfGuests: formValues.numberOfGuests!,
            guestFirstName: formValues.guestFirstName!,
            guestLastName: formValues.guestLastName!,
            phone: formValues.phone!,
            guestLocale: i18n.language,
          });
        }}
        disabled={
          !formValues.guestEmail ||
          !formValues.checkinDate ||
          !formValues.guestFirstName ||
          !formValues.guestLastName ||
          !formValues.phone
        }
      >
        {creating && <Loader />}
        {!creating && t("Inquiry.AgreeAndContinue")}
      </Button>
      <TextMuted>
        <Trans
          i18nKey={"FinalizeBooking.AcceptTerms"}
          components={{
            terms: (
              <a href={termsLink} target="_blank" rel="noreferrer">
                terms
              </a>
            ),
            privacy: (
              <a href={privacyLink} target="_blank" rel="noreferrer">
                privacy
              </a>
            ),
          }}
        />
      </TextMuted>
      <PropertyInquiryErrors error={error} />
    </>
  );
}
