import { lazy, Suspense } from "react";
import { RouteObject } from "react-router";
import { Outlet } from "react-router-dom";
import LoaderBox from "../libs/shared/components/LoaderBox";
import Redirect from "../libs/shared/components/Redirect";
import EarlyCheckinSuccessPage from "./EarlyCheckin/EarlyCheckinSuccessPage";
import ErrorPage from "./ErrorPage";
import FinalizeBookingPage from "./FinalizeBooking/FinalizeBookingPage";
import GuestAuthWrapper from "./components/AuthWrapper";
import GuestLayout from "./components/GuestLayout";
import { ReservationProvider } from "./providers/ReservationProvider";
import { SessionProvider } from "./providers/SessionProvider";

const GuestPortalPage = lazy(() => import("./GuestPortalPage"));
const ProlongPage = lazy(() => import("./ProlongPage"));
const LateCheckoutSuccessPage = lazy(
  () => import("./LateCheckout/LateCheckoutSuccessPage")
);
const InvoicePage = lazy(() => import("./Invoice/InvoicePage"));
const BookingSuccessPage = lazy(() => import("./BookingSuccessPage"));
const BabyCotPage = lazy(() => import("./BabyCot/BabyCotPage"));
const LateCheckoutPage = lazy(() => import("./LateCheckout/LateCheckoutPage"));
const BookCleaningPage = lazy(() => import("./BookCleaning/BookCleaningPage"));
const EarlyCheckinPage = lazy(() => import("./EarlyCheckin/EarlyCheckinPage"));
const ArriveEarlyPage = lazy(() => import("./ArriveEarly/ArriveEarlyPage"));
const CheckInPage = lazy(() => import("./CheckIn/CheckInPage"));

const publicGuestRoutes: RouteObject[] = [
  {
    path: "/gp",
    element: (
      <Suspense fallback={<LoaderBox />}>
        <SessionProvider>
          <GuestLayout>
            <GuestAuthWrapper isPublic={true}>
              <ReservationProvider>
                <Outlet />
              </ReservationProvider>
            </GuestAuthWrapper>
          </GuestLayout>
        </SessionProvider>
      </Suspense>
    ),
    errorElement: (
      <GuestLayout>
        <ErrorPage />
      </GuestLayout>
    ),
    children: [
      {
        path: ":reservationId",
        element: <GuestPortalPage />,
      },
      {
        path: ":reservationId/prolong",
        element: <ProlongPage />,
      },
      {
        path: ":reservationId/checkin",
        element: <CheckInPage />,
      },
      {
        path: ":reservationId/arrive-early",
        element: <ArriveEarlyPage />,
      },
      {
        path: ":reservationId/early_checkin",
        element: <EarlyCheckinPage />,
      },
      {
        path: ":reservationId/cleaning",
        element: <BookCleaningPage />,
      },
      {
        path: ":reservationId/late_checkout",
        element: <LateCheckoutPage />,
      },
      {
        path: ":reservationId/baby_cot",
        element: <BabyCotPage />,
      },
      {
        path: ":reservationId/finalize",
        element: <FinalizeBookingPage />,
      },
      {
        path: ":reservationId/finalize/success",
        element: <BookingSuccessPage />,
      },
      {
        path: ":reservationId/late_checkout/success",
        element: <LateCheckoutSuccessPage />,
      },
      {
        path: ":reservationId/early_checkin/success",
        element: <EarlyCheckinSuccessPage />,
      },
    ],
  },
];

const authenticatedGuestRoutes: RouteObject[] = [
  {
    path: "/gp",
    element: (
      <Suspense fallback={<LoaderBox />}>
        <SessionProvider>
          <GuestLayout>
            <GuestAuthWrapper isPublic={false}>
              <ReservationProvider>
                <Outlet />
              </ReservationProvider>
            </GuestAuthWrapper>
          </GuestLayout>
        </SessionProvider>
      </Suspense>
    ),
    errorElement: (
      <GuestLayout>
        <ErrorPage />
      </GuestLayout>
    ),
    children: [
      {
        path: ":reservationId/invoice",
        element: <InvoicePage />,
      },
    ],
  },
];

const compatGuestRoutes: RouteObject[] = [
  {
    path: "/invoice/:reservationId",
    element: (
      <Redirect
        to="/gp/:reservationId/invoice"
        paramNames={["reservationId"]}
      />
    ),
  },
  {
    path: "/prolong/:reservationId",
    element: (
      <Redirect
        to="/gp/:reservationId/prolong"
        paramNames={["reservationId"]}
      />
    ),
  },
  {
    path: "/early_checkin/:reservationId",
    element: (
      <Redirect
        to="/gp/:reservationId/early_checkin"
        paramNames={["reservationId"]}
      />
    ),
  },
  {
    path: "/late_checkout/:reservationId",
    element: (
      <Redirect
        to="/gp/:reservationId/late_checkout"
        paramNames={["reservationId"]}
      />
    ),
  },
  {
    path: "/finalize/:reservationId",
    element: (
      <Redirect
        to="/gp/:reservationId/finalize"
        paramNames={["reservationId"]}
      />
    ),
  },
  {
    path: "/finalize/:reservationId/success",
    element: (
      <Redirect
        to="/gp/:reservationId/finalize/success"
        paramNames={["reservationId"]}
      />
    ),
  },
  {
    path: "/late_checkout/:reservationId/success",
    element: (
      <Redirect
        to="/gp/:reservationId/late_checkout/success"
        paramNames={["reservationId"]}
      />
    ),
  },
];

const routes = [
  ...publicGuestRoutes,
  ...authenticatedGuestRoutes,
  ...compatGuestRoutes,
  { path: "*", element: <Redirect to="/" paramNames={[]} /> },
];

export default routes;
