import { Divider, Flex, Placeholder } from "@aws-amplify/ui-react";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TextBold } from "../Discovery/components/DiscoveryTypography";
import {
  HABookableListing,
  usePropertyPrice,
} from "../libs/shared/hooks/useProperty";
import { InquiryParam } from "./InquiryParam";

export default function PropertyInquiryPricing({
  inquiry,
  listing: { id },
}: {
  inquiry: InquiryParam;
  imageUrl?: string;
  onChange?: (params: InquiryParam) => void;
  displayCtaButton?: boolean;
  listing: Pick<HABookableListing, "id">;
}) {
  const { t } = useTranslation();

  const {
    data: priceData,
    load: loadPrice,
    loading,
  } = usePropertyPrice(Number(id));

  const canCalculate = useMemo(() => {
    return inquiry.arrival && inquiry.departure && inquiry.numberOfGuests;
  }, [inquiry]);

  useEffect(() => {
    if (inquiry.arrival && inquiry.departure && inquiry.numberOfGuests) {
      loadPrice({
        arrivalDate: inquiry.arrival!,
        departureDate: inquiry.departure!,
        numberOfGuests: inquiry.numberOfGuests!,
        prolongingReservationId: inquiry.prolongingReservationId,
      });
    }
  }, [inquiry]);

  return (
    <Flex direction="column" gap="20px">
      {loading && (
        <Flex direction={"column"}>
          <Placeholder height={35}></Placeholder>
          <Placeholder height={35}></Placeholder>
          <Placeholder height={35}></Placeholder>
          <Placeholder height={35}></Placeholder>
        </Flex>
      )}

      {!loading && priceData && canCalculate && (
        <Flex direction={"column"} gap={6}>
          <Flex justifyContent={"space-between"}>
            <Flex>{t("Inquiry.BasePrice")}</Flex>
            <Flex>{priceData.baseRate.toFixed(2)} zł</Flex>
          </Flex>
          {!!priceData.monthlyDiscount && (
            <Flex justifyContent={"space-between"}>
              <Flex>{t("Inquiry.MonthlyDiscount")}</Flex>
              <Flex>{priceData.monthlyDiscount.toFixed(2)} zł</Flex>
            </Flex>
          )}
          {!!priceData.weeklyDiscount && (
            <Flex justifyContent={"space-between"}>
              <Flex>{t("Inquiry.WeeklyDiscount")}</Flex>
              <Flex>{priceData.weeklyDiscount.toFixed(2)} zł</Flex>
            </Flex>
          )}
          {!!priceData.pricePerExtraPerson && (
            <Flex justifyContent={"space-between"}>
              <Flex>{t("Inquiry.AdditionalPersons")}</Flex>
              <Flex>{priceData.pricePerExtraPerson.toFixed(2)} zł</Flex>
            </Flex>
          )}
          <Flex justifyContent={"space-between"}>
            <Flex>{t("Inquiry.Tax")}</Flex>
            <Flex>{priceData.vat.toFixed(2)} zł</Flex>
          </Flex>

          {!!priceData.cleaningFee && (
            <Flex justifyContent={"space-between"}>
              <Flex>{t("Inquiry.CleaningFee")}</Flex>
              <Flex>{priceData.cleaningFee.toFixed(2)} zł</Flex>
            </Flex>
          )}

          <Divider></Divider>
          <Flex justifyContent={"space-between"}>
            <Flex>
              <TextBold>{t("Inquiry.Total")}</TextBold>
            </Flex>
            <Flex>
              <TextBold>{priceData.totalPrice.toFixed(2)} zł</TextBold>
            </Flex>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
}
