import { Authenticator, Flex } from "@aws-amplify/ui-react";
import { lazy, Suspense } from "react";
import { RouteObject } from "react-router";
import AuthWrapper from "./Auth/AuthWrapper";
import Box from "./libs/shared/components/Box";
import LoaderBox from "./libs/shared/components/LoaderBox";
import RentujemyLogo from "./libs/shared/components/RentujemyLogo";
import UserLanguageApplier from "./libs/shared/components/UserLanguageApplier";
import { UserProfileProvider } from "./libs/shared/components/UserProfileProvider";

function applyWrappers(routes: RouteObject[]) {
  return routes.map((item) => {
    item.errorElement = (
      <Flex alignItems="center" justifyContent="center" height="100vh">
        <Flex direction={"column"} textAlign={"center"}>
          <Box style={{ margin: "0 auto", maxWidth: 600, textAlign: "center" }}>
            <RentujemyLogo />
          </Box>

          <p>Przepraszamy, ale coś poszło nie tak po naszej stronie.</p>
          <p>
            Prosimy spróbować ponownie później. Jeśli problem będzie się
            powtarzał, prosimy o kontakt z naszym działem obsługi klienta.
          </p>
          <p>
            <a href="https://rentujemy.pl">Wróć na stronę główną</a>
          </p>
        </Flex>
      </Flex>
    );

    item.element = (
      <Suspense fallback={<LoaderBox />}>
        <Authenticator.Provider>
          <AuthWrapper>
            <UserProfileProvider>
              <UserLanguageApplier>{item.element}</UserLanguageApplier>
            </UserProfileProvider>
          </AuthWrapper>
        </Authenticator.Provider>
      </Suspense>
    );

    return item;
  });
}

const OwnerCockpitPage = lazy(
  () => import("./OwnerCockpitPage/OwnerCockpitPage")
);
const GuestReservations = lazy(() => import("./Guest/GuestReservations"));
const PayoutsPage = lazy(() => import("./Payouts/PayoutsPage"));
const OwnerStayPage = lazy(() => import("./OwnerCockpitPage/OwnerStayPage"));
const OwnerPropertyReservationPage = lazy(
  () => import("./OwnerCockpitPage/OwnerPropertyReservationPage")
);
const ProfileSettings = lazy(() => import("./Profile/ProfileSettings"));

const customerRoutes: RouteObject[] = [
  ...applyWrappers([
    {
      path: "/",
      element: <OwnerCockpitPage />,
    },
    {
      path: "trips",
      element: <GuestReservations />,
    },

    {
      path: "/owner-cockpit",
      element: <OwnerCockpitPage />,
    },
    {
      path: "/owner-cockpit/:contractType/:contractId",
      element: <OwnerPropertyReservationPage />,
    },
    {
      path: "/owner-cockpit/:id/owner-stay",
      element: <OwnerStayPage />,
    },
    {
      path: "/payouts",
      element: <PayoutsPage />,
    },
    {
      path: "/settings/user",
      element: <ProfileSettings />,
    },
  ]),
];

export default customerRoutes;
