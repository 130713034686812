import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { useCurrentUser } from "../../../hooks/useCurrentUser";
import { logger } from "../../../logger";
import { UserDetails } from "../types/user-details";
import { getCurrentUserData } from "../hooks/useCurrentUser";

const UserProfileContext = createContext<{
  reloadUserProfile: () => void;
  userProfile: UserDetails | null;
  loading: boolean;
}>({
  loading: true,
  reloadUserProfile: () => {},
  userProfile: null,
});

UserProfileContext.displayName = "User Profile";

const useUserProfile = () => useContext(UserProfileContext);

const { Consumer, Provider } = UserProfileContext;

function UserProfileProvider({ children }: PropsWithChildren) {
  const { authStatus, cognitoId } = useCurrentUser();
  const [userProfile, setUserProfile] = useState<UserDetails | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const loadUserProfile = useCallback(async () => {
    setLoading(true);

    try {
      if (authStatus !== "authenticated") {
        setUserProfile(null);

        setLoading(false);
        return;
      }

      setUserProfile(await getCurrentUserData());

      setLoading(false);
    } catch (error) {
      logger.error(
        `Could not load user data for ID ${cognitoId}`,
        (error as Error).message
      );

      setLoading(false);
    }
  }, [authStatus, cognitoId]);

  useEffect(() => {
    loadUserProfile();
  }, [loadUserProfile]);

  return (
    <Provider
      value={{ loading, userProfile, reloadUserProfile: loadUserProfile }}
    >
      {children}
    </Provider>
  );
}

export { Consumer as UserProfileConsumer, UserProfileProvider, useUserProfile };
