import SEO from "@americanexpress/react-seo";
import { Button, Divider, Flex } from "@aws-amplify/ui-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import DiscoveryFooter from "../Discovery/components/DiscoveryFooter";
import DiscoveryHeader from "../Discovery/components/DiscoveryHeader";
import DiscoverySearchBar from "../Discovery/components/DiscoverySearchBar";
import { KEYWORDS } from "../Discovery/KEYWORDS";
import { useAnalytics } from "../hooks/useAnalytics";
import Container from "../libs/shared/components/Container";
import { buildQueryString } from "../libs/shared/hooks/buildQueryString";
import { useIsMatchingMobile } from "../libs/shared/hooks/useIsMatchingMobile";
import { useProperty } from "../libs/shared/hooks/useProperty";
import PropertyInquiryBottomsheet from "../PropertyInquiry/PropertyInquiryBottomsheet";
import PropertyInquiryForm from "../PropertyInquiry/PropertyInquiryForm";

import PropertyAmenities from "./PropertyAmenities";
import PropertyDescriptionSection from "./PropertyDescriptionSection";
import PropertyDiscounts from "./PropertyDiscounts";
import PropertyHeaderSection from "./PropertyHeaderSection";
import PropertyImages from "./PropertyImages";
import PropertyIntroSection from "./PropertyIntroSection";
import PropertyNeighboorhood from "./PropertyNeighboorhood";
import PropertyPlaceholder from "./PropertyPlaceholder";
import PropertyReservationTerms from "./PropertyReservationTerms";
import { InquiryParam } from "../PropertyInquiry/InquiryParam";

export default function GuestPropertyPage() {
  useAnalytics();

  const { t } = useTranslation();
  const params = useParams();
  const { data, loading } = useProperty(Number(params.propertyId));
  const navigate = useNavigate();
  const isMatchingMobile = useIsMatchingMobile();
  const [openInquiry, setOpenInquiry] = useState<boolean>(false);

  const handleInquiry = (inquiry: InquiryParam) => {
    navigate(
      `/listings/${data?.id}/inquiry${buildQueryString({ ...inquiry })}`
    );
  };

  return (
    <>
      {data && (
        <PropertyInquiryBottomsheet
          listing={data}
          onDismiss={() => setOpenInquiry(false)}
          open={openInquiry}
          onSelected={handleInquiry}
        />
      )}

      {data && (
        <SEO
          title={`${data?.city}, ${data?.name} | RENTUJEMY`}
          description={t("SEO.Discovery.Description")}
          keywords={KEYWORDS}
          siteUrl={`https://rentujemy.com/property/${data.id}`}
          image={{
            src: data.listingImages[0].url,
          }}
        />
      )}

      <DiscoveryHeader></DiscoveryHeader>

      <Container>
        {!isMatchingMobile && (
          <DiscoverySearchBar
            defaultValues={{}}
            onSubmit={(values) => {
              const newSearchParams = buildQueryString({ ...values });

              navigate(`/?${newSearchParams.toString()}`);
            }}
          ></DiscoverySearchBar>
        )}

        {loading && !data && <PropertyPlaceholder />}

        {data && (
          <Flex direction={"column"} paddingBottom={200}>
            <PropertyHeaderSection listing={data}></PropertyHeaderSection>

            <PropertyImages listingImages={data.listingImages}></PropertyImages>

            <Flex gap={32}>
              <Flex direction={"column"}>
                <PropertyIntroSection listing={data}></PropertyIntroSection>

                <Divider />

                <PropertyDescriptionSection
                  listing={data}
                ></PropertyDescriptionSection>

                <Divider />

                <PropertyAmenities listing={data}></PropertyAmenities>

                <Divider />

                <PropertyDiscounts listing={data}></PropertyDiscounts>

                <Divider />

                <PropertyNeighboorhood listing={data}></PropertyNeighboorhood>

                <Divider />

                <PropertyReservationTerms
                  listing={data}
                ></PropertyReservationTerms>
              </Flex>

              {!isMatchingMobile && (
                <Flex>
                  <PropertyInquiryForm
                    onSelected={handleInquiry}
                    listing={data}
                  ></PropertyInquiryForm>
                </Flex>
              )}
            </Flex>
          </Flex>
        )}
      </Container>

      <DiscoveryFooter />

      {isMatchingMobile && (
        <Flex
          position={"fixed"}
          bottom={0}
          left={0}
          right={-1}
          borderRadius={0}
        >
          <Button
            height={70}
            variation="primary"
            size="large"
            grow={1}
            padding={-1}
            borderRadius={0}
            onClick={() => {
              setOpenInquiry(true);
            }}
          >
            {t("Property.CheckAvailability")}
          </Button>
        </Flex>
      )}
    </>
  );
}
