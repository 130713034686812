import { useCallback, useState } from "react";

export default function useForm<FormValues>(
  defaultValues: FormValues,
  options?: { validate?: (values: FormValues) => boolean }
) {
  const [dirty, setDirty] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<FormValues>(defaultValues);
  const [valid, setValid] = useState<boolean>(false);

  const setFieldValue = useCallback(
    (
      fieldKey: keyof FormValues,
      fieldValue: string | number | undefined | null | boolean
    ) => {
      setFormValues({
        ...formValues,
        [fieldKey]: fieldValue,
      });

      setDirty(true);

      if (options?.validate) {
        setValid(options.validate(formValues));
      } else {
        setValid(true);
      }
    },
    [setFormValues, setDirty, setValid, formValues]
  );

  return {
    dirty,
    setDirty,
    formValues,
    setFieldValue,
    setFormValues,
    valid,
  };
}
