import { Alert } from "@aws-amplify/ui-react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { InquiryErrors } from "../Guest/types/InquiryErrors";

export default function PropertyInquiryErrors({
  error,
}: {
  error: Error | undefined;
}) {
  const { t } = useTranslation();

  const errorMessage = useMemo(() => {
    if (error?.message.includes(InquiryErrors.NotAvailable)) {
      return t("GuestReservation.NotAvailable");
    } else if (error?.message.includes(InquiryErrors.Unknown)) {
      return t("GuestReservation.FailedMessage");
    } else if (error) {
      return error?.message;
    }

    return undefined;
  }, [error]);

  return (
    <>
      {errorMessage && (
        <Alert variation="error" isDismissible={false} hasIcon={true}>
          {errorMessage}
        </Alert>
      )}
    </>
  );
}
