import { Flex, Text } from "@aws-amplify/ui-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput, { PhoneInputProps } from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export default function PhoneNumberField({
  value,
  onChange,
  ...phoneInputProps
}: {
  value: string;
  onChange: (phoneNumber: string) => void;
} & PhoneInputProps) {
  const { t } = useTranslation();
  const [userCountry, setUserCountry] = useState("pl"); // Default country

  useEffect(() => {
    const language = navigator.language || (navigator as any).userLanguage; // Get browser language
    const countryCode = language.split("-")[1]?.toLowerCase(); // Extract country code

    if (countryCode) {
      setUserCountry(countryCode);
    }
  }, [setUserCountry]);

  return (
    <Flex direction={"column"} gap={4}>
      <Text>{t("Account.Phone")}</Text>
      <PhoneInput
        specialLabel="Phone"
        inputStyle={{
          width: "100%",
          borderColor: "var(--amplify-components-fieldcontrol-border-color)",
          height: 42,
        }}
        containerStyle={{
          width: "100%",
        }}
        country={userCountry}
        value={value}
        enableSearch={true}
        autoFormat={true}
        onChange={(phone) => onChange(phone)}
        {...phoneInputProps}
      />
    </Flex>
  );
}
