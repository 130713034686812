import { Flex, useAuthenticator } from "@aws-amplify/ui-react";
import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import { useTranslation } from "react-i18next";
import { GiHamburgerMenu } from "react-icons/gi";
import { useLocation, useNavigate } from "react-router-dom";
import { clearAllCache } from "../../hooks/useCache";
import {
  StyledItem,
  StyledToggleButton,
} from "../../molecules/Header/MenuMobile.styled";
import { borderBottomStyle } from "../commonStyles";
import { TextMuted } from "./DiscoveryTypography";

const MenuSection = ({ label }: { label: string }) => {
  return (
    <Flex
      style={{ ...borderBottomStyle }}
      marginTop={16}
      paddingBottom={8}
      paddingLeft={"var(--bs-dropdown-item-padding-x)"}
      paddingRight={"var(--bs-dropdown-item-padding-x)"}
    >
      <TextMuted fontSize={12}>{label}</TextMuted>
    </Flex>
  );
};

export default function DiscoveryMenuMobile() {
  const [currentLocation, setCurrentLocation] = useState("");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { signOut, user } = useAuthenticator((context) => [context.user]);

  useEffect(() => {
    setCurrentLocation(location.pathname);
  }, [location.pathname]);

  return (
    <Flex>
      <Flex>
        <Dropdown>
          <StyledToggleButton>
            <GiHamburgerMenu />
          </StyledToggleButton>
          <DropdownMenu>
            {!user && (
              <>
                <StyledItem
                  style={{ ...borderBottomStyle }}
                  onClick={() => {
                    navigate("/login");
                  }}
                  active={currentLocation === "/login"}
                >
                  {t("Tabs.Login")}
                </StyledItem>
              </>
            )}

            {user && (
              <StyledItem
                onClick={() => navigate("/settings/user")}
                active={currentLocation === "/settings/user"}
              >
                {t("Menu.Profile")}
              </StyledItem>
            )}

            <MenuSection label={t("Menu.GuestSection")}></MenuSection>
            <StyledItem onClick={() => navigate("/")}>
              {t("Header.Home")}
            </StyledItem>

            <StyledItem
              onClick={() => {
                navigate("/trips");

                window.location.reload();
              }}
              active={currentLocation === "/trips"}
            >
              {t("Tabs.Trips")}
            </StyledItem>

            {user && (
              <>
                <MenuSection label={t("Menu.HostSection")}></MenuSection>

                <StyledItem
                  onClick={() => {
                    navigate("/owner-cockpit");

                    window.location.reload();
                  }}
                  active={currentLocation === "/owner-cockpit"}
                >
                  {t("Menu.OwnerCockpit")}
                </StyledItem>

                <StyledItem
                  onClick={() => navigate("/payouts")}
                  active={currentLocation === "/payouts"}
                >
                  {t("Menu.Payouts")}
                </StyledItem>
              </>
            )}

            {!!user && !!signOut && (
              <>
                <MenuSection label={""}></MenuSection>

                <StyledItem
                  onClick={() => {
                    clearAllCache();
                    signOut();
                  }}
                >
                  {t("Logout")}
                </StyledItem>
              </>
            )}
          </DropdownMenu>
        </Dropdown>
      </Flex>
    </Flex>
  );
}
